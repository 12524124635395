export default {
  IMPERSONATE_USERS: 'IMPERSONATE_USERS',
  ADMINISTER_USERS: 'ADMINISTER_USERS',
  ADMINISTER_PROJECTS: 'ADMINISTER_PROJECTS',
  ADMINISTER_ORGANISATIONS: 'ADMINISTER_ORGANISATIONS',
  ADMINISTER_CONTRACTORS: 'ADMINISTER_CONTRACTORS',
  ADMINISTER_FRAMEWORKS: 'ADMINISTER_FRAMEWORKS',
  ADMINISTER_PEOPLE: 'ADMINISTER_PEOPLE',
  CAN_ACCESS: 'CAN_ACCESS',
  ADMINISTER_COMPANY: 'ADMINISTER_COMPANY',
  ADMINISTER_CONFIGURATION: 'ADMINISTER_CONFIGURATION',
  ADMINISTER_CONVERSION_RATES: 'ADMINISTER_CONVERSION_RATES',
};
