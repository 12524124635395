import {
  createRouter, createWebHistory,
} from 'vue-router';
import { storeToRefs } from 'pinia';
import useUserStore from '@js/store/user.store';
import useAppStore from '@js/store/app.store';
import usePermissions from '@js/store/permissions.store';
import { PERMISSION } from '@js/const';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    meta: {
      scope: false,
      side: false,
    },
    component: () => import(/* webpackChunkName: "all" */'@js/views/errors/Error404.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */'@js/views/DashboardIndex.vue'),
  },
  {
    path: '/customisation',
    name: 'customisation',
    redirect: { name: 'branding' },
    component: () => import(/* webpackChunkName: "customisation" */'@js/views/CustomisationIndex.vue'),
    children: [{
      path: 'branding',
      name: 'branding',
      meta: {
        permissions: [PERMISSION.ADMINISTER_COMPANY],
      },
      component: () => import(/* webpackChunkName: "customisation" */'@js/views/CustomisationBranding.vue'),
    }, {
      path: 'terminology',
      name: 'terminology',
      meta: {
        permissions: [PERMISSION.ADMINISTER_COMPANY],
      },
      component: () => import(/* webpackChunkName: "terminology" */'@js/views/CustomisationTerminology.vue'),
    }],
  },
  {
    path: '/configuration',
    name: 'configuration',
    redirect: { name: 'regionalOffices' },
    component: () => import(/* webpackChunkName: "configuration" */'@js/views/ConfigurationIndex.vue'),
    children: [{
      path: 'regional-offices',
      name: 'regionalOffices',
      meta: {
        permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
      },
      component: () => import(/* webpackChunkName: "configuration" */'@js/views/ConfigurationRegionalOfficesIndex.vue'),
    },
    {
      path: 'sectors',
      name: 'sectors',
      meta: {
        permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
      },
      component: () => import(/* webpackChunkName: "configuration" */'@js/views/ConfigurationSectorsIndex.vue'),
    },
    {
      path: 'regions',
      name: 'regions',
      meta: {
        permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
      },
      component: () => import(/* webpackChunkName: "configuration" */'@js/views/ConfigurationRegionsIndex.vue'),
    }],
  },

  // Regional Offices
  {
    path: '/configuration/regional-offices/create',
    name: 'createRegionalOffice',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "regionalOffice" */'@js/views/ConfigurationRegionalOfficesCreateEdit.vue'),
  },
  {
    path: '/configuration/regional-offices/:id/edit',
    name: 'editRegionalOffice',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "regionalOffice" */'@js/views/ConfigurationRegionalOfficesCreateEdit.vue'),
  },

  // Sectors
  {
    path: '/configuration/sectors/create',
    name: 'createSector',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "sector" */'@js/views/ConfigurationSectorsCreateEdit.vue'),
  },
  {
    path: '/configuration/sectors/:id/edit',
    name: 'editSector',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "sector" */'@js/views/ConfigurationSectorsCreateEdit.vue'),
  },

  {
    path: '/configuration/regions/create',
    name: 'createRegion',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "region" */'@js/views/ConfigurationRegionsCreateEdit.vue'),
  },
  {
    path: '/configuration/regions/:id/edit',
    name: 'editRegion',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONFIGURATION],
    },
    component: () => import(/* webpackChunkName: "region" */'@js/views/ConfigurationRegionsCreateEdit.vue'),
  },

  // Conversions
  {
    path: '/conversions',
    name: 'conversions',
    redirect: { name: 'conversionsDefra' },
    meta: {
      container: false,
      permissions: [PERMISSION.ADMINISTER_CONVERSION_RATES],
    },
    children: [
      {
        path: 'defra/:id?',
        name: 'conversionsDefra',
        props: true,
        meta: {
          permissions: [PERMISSION.ADMINISTER_CONVERSION_RATES],
        },
        component: () => import(/* webpackChunkName: "organisations" */'@js/views/ConversionDefra.vue'),
      },
    ],
  },

  // Organisations
  {
    path: '/organisations',
    name: 'organisations',
    meta: {
      permissions: [PERMISSION.ADMINISTER_ORGANISATIONS],
    },
    component: () => import(/* webpackChunkName: "organisations" */'@js/views/OrganisationsIndex.vue'),
  },
  {
    path: '/organisations/:id/edit',
    name: 'editOrganisation',
    meta: {
      permissions: [PERMISSION.ADMINISTER_ORGANISATIONS],
    },
    component: () => import(/* webpackChunkName: "organisations" */'@js/views/OrganisationsCreateEdit.vue'),
  },
  {
    path: '/organisations/create',
    name: 'createOrganisation',
    meta: {
      permissions: [PERMISSION.ADMINISTER_ORGANISATIONS],
    },
    component: () => import(/* webpackChunkName: "organisations" */'@js/views/OrganisationsCreateEdit.vue'),
  },
  // Contractors
  {
    path: '/contractors',
    name: 'contractors',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONTRACTORS],
    },
    component: () => import(/* webpackChunkName: "contractors" */'@js/views/ContractorsIndex.vue'),
  },
  {
    path: '/contractors/:id/edit',
    name: 'editContractor',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONTRACTORS],
    },
    component: () => import(/* webpackChunkName: "contractors" */'@js/views/ContractorsCreateEdit.vue'),
  },
  {
    path: '/contractors/create',
    name: 'createContractor',
    meta: {
      permissions: [PERMISSION.ADMINISTER_CONTRACTORS],
    },
    component: () => import(/* webpackChunkName: "contractors" */'@js/views/ContractorsCreateEdit.vue'),
  },

  // Frameworks
  {
    path: '/frameworks',
    name: 'frameworks',
    meta: {
      permissions: [PERMISSION.ADMINISTER_FRAMEWORKS],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/FrameworksIndex.vue'),
  },
  {
    path: '/frameworks/:id/edit',
    name: 'editFramework',
    meta: {
      permissions: [PERMISSION.ADMINISTER_FRAMEWORKS],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/FrameworksCreateEdit.vue'),
  },
  {
    path: '/frameworks/create',
    name: 'createFramework',
    meta: {
      permissions: [PERMISSION.ADMINISTER_FRAMEWORKS],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/FrameworksCreateEdit.vue'),
  },

  // Projects
  {
    path: '/projects',
    name: 'projects',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PROJECTS],
    },
    component: () => import(/* webpackChunkName: "projects" */'@js/views/ProjectsIndex.vue'),
  },
  {
    path: '/projects/:id/edit',
    name: 'editProject',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PROJECTS],
    },
    component: () => import(/* webpackChunkName: "projects" */'@js/views/ProjectsCreateEdit.vue'),
  },
  {
    path: '/projects/create',
    name: 'createProject',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PROJECTS],
    },
    component: () => import(/* webpackChunkName: "projects" */'@js/views/ProjectsCreateEdit.vue'),
  },

  // People
  {
    path: '/people',
    name: 'people',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PEOPLE],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/PeopleIndex.vue'),
  },
  {
    path: '/people/:id/edit',
    name: 'editPerson',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PEOPLE],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/PeopleCreateEdit.vue'),
  },
  {
    path: '/people/create',
    name: 'createPerson',
    meta: {
      permissions: [PERMISSION.ADMINISTER_PEOPLE],
    },
    component: () => import(/* webpackChunkName: "frameworks" */'@js/views/PeopleCreateEdit.vue'),
  },

  // Users
  {
    path: '/users',
    name: 'users',
    meta: {
      permissions: [PERMISSION.ADMINISTER_USERS],
    },
    component: () => import(/* webpackChunkName: "users" */'@js/views/UsersIndex.vue'),
  },
  {
    path: '/users/create',
    name: 'createUser',
    redirect: { name: 'createUserDetails' },
    component: () => import(/* webpackChunkName: "users" */'@js/views/UsersCreateEditIndex.vue'),
    children: [{
      path: '',
      name: 'createUserDetails',
      meta: {
        permissions: [PERMISSION.ADMINISTER_USERS],
      },
      component: () => import(/* webpackChunkName: "users" */'@js/views/UsersCreateEditDetails.vue'),
    }],
  },
  {
    path: '/users/:id/edit',
    name: 'editUser',
    redirect: { name: 'editUserDetails' },
    component: () => import(/* webpackChunkName: "users" */'@js/views/UsersCreateEditIndex.vue'),
    children: [{
      path: 'details',
      name: 'editUserDetails',
      meta: {
        permissions: [PERMISSION.ADMINISTER_USERS],
      },
      component: () => import(/* webpackChunkName: "users" */'@js/views/UsersCreateEditDetails.vue'),
    },
    {
      path: 'permissions',
      name: 'editUserPermissions',
      meta: {
        permissions: [PERMISSION.ADMINISTER_USERS],
      },
      component: () => import(/* webpackChunkName: "users" */'@js/views/UsersCreateEditPermissions.vue'),
    }],
  },

  // Profile
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */'@js/views/UsersProfileEdit.vue'),
  },

  // Profile
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "profile" */'@js/views/PrivacyPolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior() {
    return { top: 0, el: 'body' };
  },
});

export const initRouter = (pinia) => {
  const userStore = useUserStore(pinia);
  const appStore = useAppStore(pinia);
  const permissionsStore = usePermissions(pinia);
  const { contextId } = storeToRefs(appStore);
  const { flags } = storeToRefs(permissionsStore);

  // Splash Screen
  router.beforeResolve(async (to, from) => {
    // Set contextual ID
    if (to.params.id) {
      contextId.value = to.params.id;
    } else {
      contextId.value = null;
    }
    // Splash Screen
    if (typeof from.name === 'undefined') {
      await userStore.getUserData();
      await permissionsStore.getPermissions();
      document.body.classList.add('nav-done');
    }
    const permissionFlags = to.meta?.permissions;
    if (permissionFlags && !(permissionFlags.every((flag) => flags.value.includes(flag)))) {
      return { name: 'dashboard' };
    }
  });

  return router;
};

// Global Guards
// router.beforeEach((to, from, next) => {
//   store.commit('mutateRoute', to);
//   next();
// });

// /**
//  * @param route
//  */
// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length;
// }

// router.beforeEach((to, from, next) => {
//   if (!hasQueryParams(to) && hasQueryParams(from)) {
//     next({ ...to, query: from.query });
//   } else {
//     next();
//   }
// });

// Splash screen loading detection

// router.beforeEach((to, from, next) => {
//   // If date not set set default date
//   interface NewDates {
//     from?: string;
//     to?: string;
//   }
//
//   const dates: NewDates = {};
//
//   if (!('from' in to.query) || !('to' in to.query)) {
//     if (!('from' in to.query)) {
//       // eslint-disable-next-line no-param-reassign
//       dates.from = formatForQueryParameter(subDays(new Date(), 30));
//     }
//     if (!('to' in to.query)) {
//       // eslint-disable-next-line no-param-reassign
//       dates.to = formatForQueryParameter(endOfDay(new Date()));
//     }
//     next({ ...to, query: { ...to.query, ...dates } });
//   }
//   next();
// });

export default router;
