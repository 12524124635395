import { useCreateEndpoint } from '@js/composables/useEndpoint';
import { unref } from 'vue';
import { app } from './config/api';

export default {
  getDefra: () => useCreateEndpoint(
    (config) => (defraId) => app.get(`/api/conversion-rates/defra/${unref(defraId) ?? ''}`, config),
  ),
  deleteDefra: () => useCreateEndpoint(
    (config) => (defraId) => app.delete(`/api/conversion-rates/defra/${defraId}`, config),
  ),
  setDefra: () => useCreateEndpoint(
    (config) => (id, formData) => app.post(`/api/conversion-rates/defra${unref(id) ? `/${unref(id)}` : ''}`, formData, config),
  ),
};
